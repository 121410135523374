import * as React from "react"
import Header from "../components/header"
import { Helmet } from "react-helmet";

import Layout from "../components/layout"
import Seo from "../components/seo"

const Verkauf = () => (
  <Layout>
    <Seo title="Verkaufs Formular" />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Header />
    <div
      style={{
        marginTop: `var(--space-5)`,
      }}
    >
      <p style={{ fontWeight: `bold` }}>
        Hier finden Sie unsere derzeitigen Angebote. Schauen Sie auch zu einem
        späteren Zeitpunkt auf unsere Seite, da wir unsere Angebote ständig
        aktualisieren.
      </p>
      <div
        style={{
          width: `70%`,
          margin: `var(--space-5) auto`,
        }}
      >
        <form
          name="verkauf_form"
          method="POST"
          action="/danke-kontakt"
          data-netlify="true"
          onSubmit="submit"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="verkauf_form" />
          <p hidden>
            <label>
              Bitte nicht ausfüllen: <input name="bot-field" />
            </label>
          </p>
          <h3>Bild hochladen:</h3>
          <div className="field file-upload-wrapper">
            <div className="control">
              <div className="file-upload">
                <input name="file" type="file" />
                <i class="image-up"></i>
              </div>
            </div>
          </div>
          <h3>Type/Model</h3>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Type/Model"
                name="type-model"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Year</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="year"
                name="year"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Capacity</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Capacity"
                name="capacity"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Delivery</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Delivery"
                name="delivery"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Location</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Location"
                name="location"
              />
            </div>
          </div>
          <h3>CHASSIS</h3>
          <div className="field">
            <label className="label">Chassis Engine</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Chassis Engine"
                name="chassis-engine"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Drive-Steer</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Drive-Steer"
                name="drive-steer"
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Tyre size</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Tyre size"
                name="tyre-size"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Mileage</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Mileage"
                name="mileage"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Working hours</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Working hours"
                name="working-hours"
              />
            </div>
          </div>
          <h3>Superstructure</h3>
          <div className="field">
            <label className="label">Crane Engine</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Crane Engine"
                name="crane-engine"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Main boom</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Main boom"
                name="main-boom"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Fixed jib</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Fixed jib"
                name="fixed-jib"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Superlift</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Superlift"
                name="superlift"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Winches</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Winches"
                name="winches"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Counterweights</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Counterweights"
                name="counterweights"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Hook blocks</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Hook blocks"
                name="hook-blocks"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Working hours</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Working hours"
                name="working-hours"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Additional equipment</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Additional equipment"
                name="additional-equipment"
              ></textarea>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button type="submit" className="button is-link is-medium">
                Senden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
)

export default Verkauf
